import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class BaseService {
  apiRoot = environment.apiRoot;
  apiUrl = environment.apiUrl;
  originUrl = environment.originUrl;
  apiVenturis = environment.apiVenturis;
  apiflexible = environment.apiflexible;
  sameFlights = environment.groupSameFlights;
  requote = environment.requote;
  topGroup = environment.topGroup;
  constructor() {}
}
